<template>
	<div id="Editorial-AwardList" class="main-wrap Editorial-wrap">
		<div class="flex between w100">
			<div class="left-box"><MainMenu title="编委初评" :list="[]"/></div>

			<div class="w850" style="margin:unset">
				<template v-if="step==1">
					<div class="title-h2">LIST OF SELECTED WORKS</div>
					<div class="title-h1">{{L("已评选作品清单")}}</div>
				</template>
				<template v-if="step==2">
					<div class="title-h2">LIST OF VOTED WORKS</div>
					<div class="title-h1">{{L("已投票作品清单")}}</div>
				</template>
				
				<div class="awardType-list ">
					<div class="table">
						<div class="header">
							<div class="col cover">{{L("投稿作品")}}</div>
							<div class="col title">{{L("作品名称")}}</div>
							<div class="col code">{{L("作品编号")}}</div>
							<div class="col status">{{L("状态")}}</div>
							<!-- <div class="col action"></div> -->
						</div>
						<div class="list">
							<div v-for="item in currentList" :key="item.id">
								<transition name="fold">
									<div class="item flex middle" @click="doSelectAward(item)">
									<!-- <div class="item flex middle" @click="doSelectAward(item)" v-if="step==1 || (step==2 && scoreMap[item.id].voteType==1)"> -->
										
										<div class="col cover">
											<template v-if="item.content.images && item.content.images.length">
												<CoverWrap :src="item.content.images[0].path + '?x-oss-process=image/resize,w_400'" width="230px" height="144px"/>
											</template>
										</div>
										<div class="col title">{{item.content.title}}</div>
										<div class="col code">{{$root.createWID(item)}}</div>
										<div class="col status">
											<template v-if="step == 1">
												<template v-if="scoreMap[item.id]">
													<span v-if="scoreMap[item.id].isRecomment" class="scoreBtn score-recomment active">{{L("★推荐")}}</span>
													<span v-else class="scoreBtn active" :class="'score-'+scoreMap[item.id].score">{{L(scoreMap[item.id].score+"分")}}</span>
												</template>
												<template v-else>
													<span class="scoreBtn score-empty active">{{L("未评分")}}</span>
												</template>
											</template>
											<template v-if="step == 2">
												<template v-if="scoreMap[item.id]">
													<span class="scoreBtn " @click.stop="doScoreOrVote($event,item)" :class="{active:!!scoreMap[item.id].voteType,unvote:scoreMap[item.id].voteType==1,voted:scoreMap[item.id].voteType==-1}">{{L(scoreMap[item.id].voteType==1&&L("取消投票")||scoreMap[item.id].voteType==-1&&L("更改为投票")||L('待处理'))}}</span>
												</template>
											</template>
										</div>
										<!-- <div class="col action"></div> -->
									</div>
										
								</transition>
							</div>
							<div class="empty" v-if="!list || !list.length">{{L("暂无内容")}}</div>
						</div>
					</div>
				</div>
				
				<div class="line"></div>
				<div class="mt-10"></div>
				<div class="flex right score-info middle b">
					<template v-if="step == 1">
						<div v-if="currentAwardTypeData.name">{{L(currentAwardTypeData.name)}}{{L("类总共投稿")}} <span>{{total}}</span> {{L("件，目前已评选")}} <span>{{scoredTotal+recommentTotal}}</span> {{L("件")}}。</div>
					</template>
					<template v-if="step ==2">
						{{L(' “年度主题单元”目前已投：')}} <span>{{votedTotal}}</span> {{L("件")}}
					</template>
				</div>
				
				<div class="mt-20"></div>
				<Pagination
				  :change="doChangePage"
				  :page-size.sync="pageSize"
				  :page.sync="page"
				  :total.sync="step==1?total:votedTotal">
				</Pagination>
				
				
				<div class="mt-20"></div>
				<div class="mt-20"></div>
				<div class="mt-20"></div>
				<div class="mt-20"></div>
				
				<div class="form-tips">
					{{L("*特别说明：一旦提交评选结果将无法进行更改，请谨慎操作。")}}
				</div>
				
				<div class="mt-20"></div>
				<div class="mt-20"></div>
				<div class="action-box flex between">
					<div class="flex">
						<div class="button" @click="doBackAwardList">{{L("返回继续评选")}}</div>
						<div class="button" @click="doBackAwardTypeList">{{L("返回评选入口")}}</div>
						
					</div>
					<div class="button" @click="doSubmit" :class="{loading:isScoreLoading}" v-if="!isSubmit">{{L("提交评选结果")}}</div>
				</div>
				<div class="mt-20"></div>
			
			</div>
		</div>	
	</div>
</template>
<script>
import MainMenu from '/src/views/MainMenu';
import CoverWrap from '/src/components/CoverWrap.vue';
import Pagination from '/src/components/Pagination.vue';
export default {
  name: 'Editorial-AwardList',
  components: {
    MainMenu,
		CoverWrap,
		Pagination
  },
  data(){
    return {
			step:parseInt(this.$route.params.step) || 0,
			awardType:0,
			currentAwardTypeData:{},
			list:[],
			currentList:[],
			page:1,
			pageSize:10,
			total:0,
			scoreMap:{},
			recommentTotal:0,
			scoredTotal:0,
			isScoreLoading:false,
			isSubmit:true,
			userData:{},
			votedTotal:0
			
			
			
    };
  },
	watch:{
		$route(){
			this.initData();
		}
	},
  async mounted () {
		await this.initData();
  },
  methods: {
		doSelectAward(item){
			this.$root.toPage(`/Work/Content/${this.$root.md5(item.id)}/${item.id}/${this.step}`)
		},
		doBackAwardTypeList(){
			this.$root.toPage('/Editorial/AwardTypeList/'+this.step);
		},
		doBackAwardList(){
			if(this.step ==1){
				this.$root.toPage('/Editorial/AwardList/'+this.step+'/'+this.currentAwardTypeData.id);
			} else if(this.step==2){
				this.$root.toPage('/Editorial/AwardList/'+this.step);
			}
			
		},
		async doChangePage(page){
			this.page = page;
			this.currentList = this.list.slice((this.page-1)*this.pageSize,this.page*this.pageSize );
			
			if(this.currentList.length == 0 && this.page > 1){
				this.doChangePage(this.page-1);
			}
			// console.log((this.page-1)*this.pageSize,this.page*this.pageSize )
			// await this.getList();
		},
		async initData(){
			const awardType = parseInt(this.$route.params.id);
			this.awardType = awardType;
			
			let target = this.$root.awardTypeList.find(item=>item.id == this.awardType);
			if(target || awardType === 0){
				this.$set(this,'currentAwardTypeData',target);
				await this.initScoreMap();
				await this.getList();
			}
			
			
			
		},
		async initScoreMap(){
			let userData = await this.$root.initUserData();
			let scoreMap = {};
			if(userData && userData.config ){
				
				if(this.step == 1 && userData.config.firstScoreResult){
					let result = userData.config.firstScoreResult.find(item=>item.aid==this.awardType);
					if(!result || !result.isSubmit){
						this.isSubmit = false;
					}
					if(userData.config.firstScoreList){
						userData.config.firstScoreList.filter(item=>item.aid==this.awardType).forEach(item=>{
							scoreMap[item.wid] = this.$root.copy(item);
						})
					}
				}
				if(this.step == 2 && userData.config.secondScoreResult){
					let result = userData.config.secondScoreResult.find(item=>item.aid==this.awardType);
					if(!result || !result.isSubmit){
						this.isSubmit = false;
					}
					if(userData.config.secondScoreList){
						userData.config.secondScoreList.forEach(item=>{
							scoreMap[item.wid] = this.$root.copy(item);
						})
					}
				}
				
				
			}
			
			this.$set(this,'userData',userData);
			this.$set(this,'scoreMap',scoreMap);
			
		},
		async getCheckUser(){
			let userData = await this.$root.initUserData();
			if(!userData){
				alert("登录失效,请重新登录");
				this.$root.toPage('/User/Login');
				return false;
			}
			return userData;
		},
		async doScoreOrVote($event,workData){
			if(this.isScoreLoading){return ;}
			this.isScoreLoading = true;
			
			$event.target.className = $event.target.className + " loading";
			
			
			let userData = await this.getCheckUser();
			if(!userData){
				this.isScoreLoading = false;
				return false;
			}
			
			let config = {};
			let voteType = 0;
			if(this.step == 2){
				
				if(!this.scoreMap[workData.id] || this.scoreMap[workData.id].voteType == -1){
					voteType = 1;
				} else {
					voteType = -1;
				}
				let secondScoreList = (userData.config &&  userData.config.secondScoreList) ? userData.config.secondScoreList : [];
				
				let hasIndex = secondScoreList.findIndex(item=>item.wid == workData.id);
				if(hasIndex>-1){
					secondScoreList.splice(hasIndex,1);
				}
				secondScoreList.push({
					wid:workData.id,
					aid:workData.awardType,
					voteType
				});
				let hash = {}
				secondScoreList = secondScoreList.reduceRight((item, next) => {
				    hash[next.wid] ? '' : hash[next.wid] = true && item.push(next);
				    return item
				}, []);
				config = this.$root.copy({...userData.config,secondScoreList});
				
			} else {
				this.isScoreLoading = false;
				return ;
			}
			await this.request.post('/api/v1/frontDeskUser/editConfig',{config:JSON.stringify(config)}).then(async res=>{
				this.scoreMap[workData.id].voteType = voteType
				if(voteType == -1){
					this.votedTotal --;
				} else {
					this.votedTotal ++;
				}
				this.doChangePage(this.page);
				$event.target.className = $event.target.className.replace(/loading/g,"");
				// await this.initData();
				this.isScoreLoading = false;
			});
			
		},
		
		async getList(){
			
			
			let recommentTotal = 0;
			let scoredTotal = 0;
			let votedTotal = 0;
			
			let params = {
				page: 1,//this.page,
				pageSize:999, //this.pageSize,
			};
			if(this.step==1){
				params.awardWorkStatus = 11; // 已入选初评
				params.awardType = this.awardType;
			}else if(this.step==2){
				// params.awardWorkStatus = 11; 
				params.awardWorkStatus = 21;// 已入选终评
			}
			params = this.$root.object2urlparams(params);
			
			let res = await this.request.get(`api/v1/awardWork/paging?${params}`);
			if(res){
				this.total = res.total;
				// this.page = res.page;
				// this.pageSize = res.pageSize;
				let list = res.rows || [];
				list.forEach(item=>{
					this.$root.initWorkData(item);
				})
				let list2 = [],list3 =[],list4 = [];
				if(this.step==1){
					
					list.map((item,index)=>{
						
						if(this.scoreMap[item.id] && this.scoreMap[item.id].isRecomment){
							list2.push(item)
						} else if(this.scoreMap[item.id]){
							list3.push(item)
						} else{
							list4.push(item)
						}
					});
					list3.sort((a,b)=>{
						return this.scoreMap[b.id].score - this.scoreMap[a.id].score;
					});
					
					this.list = list2.concat(list3,list4);
					
					this.userData.config.firstScoreList.filter(item=>item.aid==this.awardType&&this.list.find(item2=>item2.id==item.wid)).forEach(item=>{
						if(item.isRecomment){
							recommentTotal++;
						} else{
							scoredTotal ++;
						}
						
					})
				} else if(this.step==2){
					list.map((item,index)=>{
						
						if(this.scoreMap[item.id] && this.scoreMap[item.id].voteType==1){
							list2.push(item)
						}
						//  else if(this.scoreMap[item.id] && this.scoreMap[item.id].voteType==-1){
						// 	list3.push(item)
						// } else{
						// 	list4.push(item)
						// }
					});
					
					this.list = list2.concat(list3,list4);
					this.total = this.list.length;
					this.userData.config.secondScoreList.filter(item=>this.list.find(item2=>item2.id==item.wid)).forEach(item=>{
						votedTotal ++;
						
					})
				}
				
				
				this.doChangePage(1);
			}
			
			this.votedTotal = votedTotal;
			this.recommentTotal = recommentTotal;
			this.scoredTotal = scoredTotal;
		},
		async doSubmit(){
			if(this.isScoreLoading){return ;}
			this.isScoreLoading = true;
			
			let userData = await this.getCheckUser();
			if(!userData){
				this.isScoreLoading = false;
				return false;
			}
			
			let config = {};
			if(this.step == 1){
				if(this.recommentTotal + this.scoredTotal < this.total){
					alert(this.L("请为全部作品（")+this.total+this.L("件）打分，当前已完成")+(this.recommentTotal + this.scoredTotal)+this.L("件作品的评分。"));
					this.isScoreLoading = false;
					return;
				}
				let firstScoreResult = (userData.config &&  userData.config.firstScoreResult) ? userData.config.firstScoreResult : [];
				let hasIndex = firstScoreResult.findIndex(item=>item.aid == this.awardType);
				if(hasIndex>-1){
					firstScoreResult.splice(hasIndex,1);
				}
				
				firstScoreResult.push({
					aid:this.awardType,
					isSubmit: true,
					recommentList:this.list.filter(item=>this.scoreMap[item.id]&&this.scoreMap[item.id].isRecomment).map(item=>item.id),
					scoreList:this.list.filter(item=>this.scoreMap[item.id]&&!this.scoreMap[item.id].isRecomment).map(item=>{return {wid:item.id,score:this.scoreMap[item.id].score}; }),
				});
				
				config = this.$root.copy({...userData.config,firstScoreResult});
			} else if(this.step == 2){
				let needVotedCount = this.userData.config.needVotedCount || 20;
				if(this.votedTotal<needVotedCount){

					alert(this.L("请选出")+needVotedCount+this.L("件“年度主题单元”作品，目前仅选出了")+this.list.filter(item=>this.scoreMap[item.id]).length+this.L("件。"));
					this.isScoreLoading = false;
					return;
				} else if(this.votedTotal>needVotedCount){

					alert(this.L("请选出")+needVotedCount+this.L("件“年度主题单元”作品，目前选出了")+this.list.filter(item=>this.scoreMap[item.id]).length+this.L("件，")+this.L("已超出了限定数量，请调整并再次确认。"));
					this.isScoreLoading = false;
					return;
				}
				let secondScoreResult = (userData.config &&  userData.config.secondScoreResult) ? userData.config.secondScoreResult : [];
				let hasIndex = secondScoreResult.findIndex(item=>item.aid === 0);
				if(hasIndex>-1){
					secondScoreResult.splice(hasIndex,1);
				}
				
				secondScoreResult.push({
					aid: 0,
					isSubmit: true,
					votedList:this.list.filter(item=>this.scoreMap[item.id]).map(item=>{return {wid:item.id,voteType:this.scoreMap[item.id].voteType}; }),
				});
				
				config = this.$root.copy({...userData.config,secondScoreResult});
			}
			await this.request.post('/api/v1/frontDeskUser/editConfig',{config:JSON.stringify(config)}).then(async res=>{
				this.isScoreLoading = false;
				
				this.$root.toPage("Message",{
					title:this.L("提交成功"),
					description:`
						<p>${this.L("评选结果已经提交成功！")}</p>
					`,
					backPath:`/Editorial/AwardTypeList/${this.step}`
				});
				
				// this.$root.initUserData();
				
				
			});
			
		}
  }
}
</script>

<style scoped lang="less">
.line{
	height:1px;
	margin-top:5px;
	background-color: #000;
}
.left-box{
	// position: absolute;
	flex-shrink: 0;
	// left:45px;
	width:150px;
}
.score-info{
	font-size:14px;
	span{
		color:#FF08FF;
		margin:0 2px;
	}
}
.awardType-list{
	.table{
		
		.header,.list{
			
			.col{
				padding: 15px 10px;
				width: 110px;
				text-align: center;
				// flex-shrink: 0;
				&.cover{
					width:230px;
					flex-shrink: 0;
					img{
						width:100%;
						max-width: 100%;
					}
				}
				&.title{
					width:100%;
				}
				&.code{
					width:145px;
					flex-shrink: 0;
				}
				&.status{
					width:125px;
					flex-shrink: 0;
				}
				&.action{
					width:100px;
					flex-shrink: 0;
				}
			}
		}
		
		
		.header{
			line-height: 1;
			background-color: #000000;
			line-height: 31px;
			display: flex;
			color: #FFF;
			font-size:16px;
			.col{
				padding: 0 0 0 0 ;
			}
		}
		.list{
			padding-bottom:10px;
			line-height: 1.5;
			font-size:14px;
			color:#000;
			.empty{
				height:80px;
				line-height: 80px;
				text-align: center;
				color:#333;
			}
			.item{
				position: relative;
				cursor: pointer;
			}
			.col{
				padding: 15px 10px;
				&.cover{
					padding:15px 0 0;
				}
				&.status{
					.scoreBtn{
						font-size:12px;
						line-height: 28px;
						padding:0 4px;
					}
				}
			}
		}
	}
	
}
</style>