<template>
  <div class="pagination flex right middle noselect" ref="pagination" v-if="total">
		<div @click="doChangePage(1)">&lt;&lt;</div>
		<div @click="doChangePage(page>1?(page-1):1)">&lt;</div>
		<div v-for="(item,index) in pages" :key="'page_'+index" :class="{active:item==page}" @click="doChangePage(item)">{{item}}</div>
		<div @click="doChangePage(page<pages?(page+1):pages)">&gt;</div>
		<div @click="doChangePage(pages)">&gt;&gt;</div>
	</div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    pageSize:{
			default:20,
			type:Number,
		},
		page:{
			default:1,
			type:Number,
		},
		total:{
			default:0,
			type:Number,
		},
		change:{
			default:null,
			type:Function,
		},
		
  },
  data(){
    return {
      pages:0
    }
  },
	watch:{
		page:function(value){
			this.createPages();
		},
		pageSize:function(value){
			this.createPages();
		},
		total:function(value){
			this.createPages();
		},
	},
	async mounted(){
		
	},
	
  methods:{
		createPages(){
			this.pages = Math.ceil(this.total/this.pageSize);
		},
		doChangePage(page){
			this.change && this.change(page);
		}
  } 
}
</script>

<style scoped lang="less">
.pagination{
	font-size:14px;
	color:#000;
	&>div{
		min-width: 12px;
		margin:0 4px;
		cursor: pointer;
		text-align: center;
		&.active{
			color:#FF08FF;
		}
		&:hover{
			background-color: rgba(0,0,0,.01);
		}
	}
}
</style>
